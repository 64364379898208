.lisa-mainmenu {
    display: flex;
    position: relative;
    width: 100%;
}

    .lisa-mainmenu > ul {
        margin: 0;
        padding: 0;
        width: auto;
        display: flex;
        flex-direction: row;
        list-style: none;
    }

.lisa-mainmenu a {
    color: #fff;
    text-decoration-color: #fff;
    text-decoration-style: solid;
    text-decoration-line: none;
    -moz-text-size-adjust: 100%;

}

.lisa-mainmenu li {
    /*flex: 1 1 0%*/
    /*display: inline;*/
}

.lisa-mainmenu-branding {
    flex: 0 1 auto;
}

.lisa-mainmenu-branding > li {
    padding-right: 15px;
}

.lisa-mainmenu-left {
    flex: 1 1 auto;
}



.lisa-mainmenu-right {
    flex: 0 1 auto;
}
